/* Noto Sans */
@font-face {
  font-family: "NotoSansBlack";
  src: url("../assets/fonts/notosans/NotoSans-Black.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansBold";
  src: url("../assets/fonts/notosans/NotoSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansExtraBold";
  src: url("../assets/fonts/notosans/NotoSans-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansExtraLight";
  src: url("../assets/fonts/notosans/NotoSans-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansLight";
  src: url("../assets/fonts/notosans/NotoSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansMedium";
  src: url("../assets/fonts/notosans/NotoSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansRegular";
  src: url("../assets/fonts/notosans/NotoSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansSemiBold";
  src: url("../assets/fonts/notosans/NotoSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansThin";
  src: url("../assets/fonts/notosans/NotoSans-Thin.ttf") format("truetype");
}

/* Spoqa Han Sans */
@font-face {
  font-family: "SpoqaHanSansBold";
  src: url("../assets/fonts/spoqa_han_sans/SpoqaHanSansNeo-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SpoqaHanSansLight";
  src: url("../assets/fonts/spoqa_han_sans/SpoqaHanSansNeo-Light.ttf") format("truetype");
}
@font-face {
  font-family: "SpoqaHanSansMedium";
  src: url("../assets/fonts/spoqa_han_sans/SpoqaHanSansNeo-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SpoqaHanSansRegular";
  src: url("../assets/fonts/spoqa_han_sans/SpoqaHanSansNeo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SpoqaHanSansThin";
  src: url("../assets/fonts/spoqa_han_sans/SpoqaHanSansNeo-Thin.ttf") format("truetype");
}

/* Nanum Gothic */
@font-face {
  font-family: "NanumGothicRegular";
  src: url("../assets/fonts/nanum_gothic/NanumGothic.ttf") format("truetype");
}
@font-face {
  font-family: "NanumGothicBold";
  src: url("../assets/fonts/nanum_gothic/NanumGothicBold.ttf") format("truetype");
}
@font-face {
  font-family: "NanumGothicExtraBold";
  src: url("../assets/fonts/nanum_gothic/NanumGothicExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "NanumGothicLight";
  src: url("../assets/fonts/nanum_gothic/NanumGothicLight.ttf") format("truetype");
}

/* Nanum Myeongjo */
@font-face {
  font-family: "NanumMyeongjoRegular";
  src: url("../assets/fonts/nanum_myeongjo/NanumMyeongjo.ttf") format("truetype");
}
@font-face {
  font-family: "NanumMyeongjoBold";
  src: url("../assets/fonts/nanum_myeongjo/NanumMyeongjoBold.ttf") format("truetype");
}
@font-face {
  font-family: "NanumMyeongjoExtraBold";
  src: url("../assets/fonts/nanum_myeongjo/NanumMyeongjoExtraBold.ttf") format("truetype");
}

/* Gothic A1 */
@font-face {
  font-family: "GothicA1Regular";
  src: url("../assets/fonts/gothic_a1/GothicA1-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1Black";
  src: url("../assets/fonts/gothic_a1/GothicA1-Black.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1Bold";
  src: url("../assets/fonts/gothic_a1/GothicA1-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1ExtraBold";
  src: url("../assets/fonts/gothic_a1/GothicA1-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1ExtraLight";
  src: url("../assets/fonts/gothic_a1/GothicA1-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1Light";
  src: url("../assets/fonts/gothic_a1/GothicA1-Light.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1Medium";
  src: url("../assets/fonts/gothic_a1/GothicA1-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1SemiBold";
  src: url("../assets/fonts/gothic_a1/GothicA1-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "GothicA1Thin";
  src: url("../assets/fonts/gothic_a1/GothicA1-Thin.ttf") format("truetype");
}

/* Noto Serif */
@font-face {
  font-family: "NotoSerifBlack";
  src: url("../assets/fonts/noto_serif/NotoSerifKR-Black.otf") format("truetype");
}
@font-face {
  font-family: "NotoSerifBold";
  src: url("../assets/fonts/noto_serif/NotoSerifKR-Bold.otf") format("truetype");
}
@font-face {
  font-family: "NotoSerifExtraLight";
  src: url("../assets/fonts/noto_serif/NotoSerifKR-ExtraLight.otf") format("truetype");
}
@font-face {
  font-family: "NotoSerifLight";
  src: url("../assets/fonts/noto_serif/NotoSerifKR-Light.otf") format("truetype");
}
@font-face {
  font-family: "NotoSerifMedium";
  src: url("../assets/fonts/noto_serif/NotoSerifKR-Medium.otf") format("truetype");
}
@font-face {
  font-family: "NotoSerifRegular";
  src: url("../assets/fonts/noto_serif/NotoSerifKR-Regular.otf") format("truetype");
}
@font-face {
  font-family: "NotoSerifSemiBold";
  src: url("../assets/fonts/noto_serif/NotoSerifKR-SemiBold.otf") format("truetype");
}

/* Pretendard */
@font-face {
  font-family: "PretendardBlack";
  src: url("../assets/fonts/pretendard/Pretendard-Black.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardBold";
  src: url("../assets/fonts/pretendard/Pretendard-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardExtraBold";
  src: url("../assets/fonts/pretendard/Pretendard-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardExtraLight";
  src: url("../assets/fonts/pretendard/Pretendard-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardLight";
  src: url("../assets/fonts/pretendard/Pretendard-Light.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardMedium";
  src: url("../assets/fonts/pretendard/Pretendard-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardRegular";
  src: url("../assets/fonts/pretendard/Pretendard-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardSemiBold";
  src: url("../assets/fonts/pretendard/Pretendard-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "PretendardThin";
  src: url("../assets/fonts/pretendard/Pretendard-Thin.ttf") format("truetype");
}